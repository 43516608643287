import italianCuisine from "../areas/onboarding/images/cuisine/italian.jpg";
import japaneseCuisine from "../areas/onboarding/images/cuisine/japanese.jpg";
import indianCuisine from "../areas/onboarding/images/cuisine/indian.jpg";
import frenchCuisine from "../areas/onboarding/images/cuisine/french.jpg";

export const config = {
    CognitoClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
    UserPoolId: process.env.GATSBY_COGNITO_USERPOOL_ID,
    CognitoUrl: process.env.GATSBY_COGNITO_URL,
    AdminUrl: process.env.GATSBY_ADMIN_URL,
    MenuApiUrl: process.env.GATSBY_MENU_API_URL,
    ClientApiHost: process.env.GATSBY_PUBLIC_CLIENT_URL,
    MenuApiKey: process.env.GATSBY_MENU_API_KEY,
    MenuTemplates: [
        {
            titleKey: "italian-cuisine",
            image: italianCuisine,
            spreadsheetId: "11hDOPPAcC3LFzBFBUCeMaznZtYu4-hXQ6GtMYkW633o",
        },
        {
            titleKey: "japanese-cuisine",
            image: japaneseCuisine,
            spreadsheetId: "1jpscV3sfWTdf0c5nw16LUvmRZVy6ak_ipOR45u_ta88",
        },
        {
            titleKey: "indian-cuisine",
            image: indianCuisine,
            spreadsheetId: "18rJwIHiuu03Uu2KNXR1OAbdawZWPS-FuxiCxkE4I3Mo",
        },
        {
            titleKey: "french-cuisine",
            image: frenchCuisine,
            spreadsheetId: "1XR8Bx5jXJZSHU6k8z6D3wrogezUm05BdlBEEKMwJvms",
        }
    ]
}   