import React, { useEffect } from "react";
import Layout from "../areas/main/components/Layout";
import logoDark from "../areas/main/assets/images/eatella-logo.png";
import { Helmet } from "react-helmet";
import "../../static/contact/contact.css";
import Texts from "../utils/lang-utils";
import { config } from "../utils/config";
import Footer from "../areas/main/components/Footer";
const generateCaptcha = () => {
  var webFormxhr = {};
      webFormxhr = new XMLHttpRequest();
      webFormxhr.open(
        "GET",
        "https://desk.zoho.eu/support/GenerateCaptcha?action=getNewCaptcha&_=" +
          new Date().getTime(),
        true
      );
      webFormxhr.onreadystatechange = function () {
        if (webFormxhr.readyState === 4 && webFormxhr.status === 200) {
          try {
            var response =
              webFormxhr.responseText != null
                ? JSON.parse(webFormxhr.responseText)
                : "";
            document.getElementById("zsCaptchaUrl").src = response.captchaUrl;
            document.getElementsByName("xJdfEaS")[0].value =
              response.captchaDigest;
            document.getElementById("zsCaptchaUrl").onclick = generateCaptcha;
          } catch (e) {}
        }
      };
      webFormxhr.send();
} 

const ContactPage = () => 
  {
    useEffect(()=> {
      generateCaptcha();
    }, []);
    return <Layout
    pageTitle="EATELLA | contact"
    description="The best app for foodies who love taking pictures of food. Transform your foodstagram content into food diary and recommendations to inspire your foodie firends"
    language="en"
  >
    <Helmet>
      <link href="/social/mobile.css" rel="stylesheet" />
    </Helmet>
    <header className="site-header site-header__header-one">
      <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <a className="navbar-brand" href="/">
              <img src={logoDark} className="main-logo" width="159" alt="alter text" />
            </a>
          </div>
          <div className="main-navigation">
            <ul className=" one-page-scroll-menu navigation-box">
              <li className="scrollToLink">
                <a></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <section className="banner-one app-intro" id="banner">
      <div className="container contact-container">
        <div class="block-title text-center"><h1 class="block-title__title">{Texts.Contact}</h1></div>
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <div className="">
              <div className="panel-body">
                <div className="text-center">
                  <form
                    name="zsWebToCase_12018000000035528"
                    id="zsWebToCase_12018000000035528"
                    role="form"
                    autoComplete="off"
                    className="form"
                    action="https://desk.zoho.eu/support/WebToCase"
                    method="POST"
                    encType="multipart/form-data"
                  >
                    <input type="hidden" name="xnQsjsdp" value="edbsn46d4b67a3a3a13c35f59eceb29bcc00a" />
                    <input type="hidden" name="xmIwtLD" value="edbsn5a4d180804f75f779fe5ecdb19968f4d00c6dd92766fe8055154ef3749dff536" />
                    <input type="hidden" name="xJdfEaS" value="" />
                    <input type="hidden" name="actionType" value="Q2FzZXM=" />
                    <input type="hidden" id="property(module)" value="Cases" />
                    <input
                      type="hidden"
                      id="dependent_field_values_Cases"
                      value='&#x7b;"JSON_VALUES"&#x3a;&#x7b;"Category"&#x3a;&#x7b;"Sub&#x20;Category"&#x3a;&#x7b;"General"&#x3a;&#x5b;"Sub&#x20;General"&#x5d;,"Defects"&#x3a;&#x5b;"Sub&#x20;Defects"&#x5d;&#x7d;&#x7d;&#x7d;,"JSON_SELECT_VALUES"&#x3a;&#x7b;"Status"&#x3a;&#x5b;"Open","On&#x20;Hold","Escalated","Closed"&#x5d;,"Category"&#x3a;&#x5b;"General","Defects"&#x5d;,"Priority"&#x3a;&#x5b;"-None-","High","Medium","Low"&#x5d;,"Classification"&#x3a;&#x5b;"-None-","Question","Problem","Feature","Others"&#x5d;,"Mode"&#x3a;&#x5b;"Phone","Email","Web","Twitter","Facebook","Chat","Forums","Feedback&#x20;Widget"&#x5d;,"Sub&#x20;Category"&#x3a;&#x5b;"Sub&#x20;General","Sub&#x20;Defects"&#x5d;&#x7d;,"JSON_MAP_DEP_LABELS"&#x3a;&#x5b;"Category"&#x5d;&#x7d;'
                    />
                    <input type="hidden" name="returnURL" value={`${config.ClientApiHost}/contact-thank-you`} />
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="Contact Name"
                          placeholder={Texts.Name}
                          className="form-control contact-field"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="Email"
                          placeholder={Texts.Email}
                          className="form-control contact-field"
                          type="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="Subject"
                          placeholder={Texts.Subject}
                          className="form-control contact-field"
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <textarea
                          name="Description"
                          placeholder={Texts.Body}
                          className="form-control contact-field"
                          required
                        ></textarea>
                      </div>
                    </div>
                    

                    <div className="form-group">
                       <img src='#' id='zsCaptchaUrl' name="zsCaptchaImage" style={{ cursor: 'pointer'}} />
                      <div className="input-group">
                        <input type='text' name='zsWebFormCaptchaWord' class="form-control contact-field" placeholder={Texts.Captcha} />
                        <input type='hidden' name='zsCaptchaSrc'  value='' />
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        name="recover-submit"
                        className="btn btn-lg btn-danger btn-block contact-submit"
                        value="Send"
                        id="zsSubmitButton_12018000000035528"
                      >
                        <span>{Texts.Send}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer activePage="contact" />
  </Layout>
};

export default ContactPage;
